// vendors
import React from 'react';

// components
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader/PageHeader';
import Heading from '../components/Heading/Heading';
import { UnderlinedButton } from '../components/Button';

const translations = [
  {
    locale: 'fr',
    url: '/livraison-et-retours/',
  },
];

export default function ShippingReturnPage() {
  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          Shipping <br />
          &amp; returns
        </PageHeader>

        <div className="container">
          <section className="max-md:block grid grid-cols-[200px_1fr] gap-20">
            <Heading level={2} className="!mt-0">
              Shipping
            </Heading>

            <div>
              <Heading level={3} className="!mt-3 text-3xl">
                How long does it takes to process my order?
              </Heading>
              <p>
                All orders are processed within{' '}
                <strong>2 to 4 business days</strong> (excluding weekends and
                holidays) after receiving your order confirmation email. You
                will receive another notification when your order has shipped.
              </p>

              <Heading level={3} className="text-3xl">
                How much does the shipping cost?
              </Heading>
              <p>
                Shipping costs are calculated based on size and weight of your
                order and displayed during the purchase process before the
                payment of your order. The carrier (Post Canada or UPS) will be
                automatically selected based on your shipping address. All
                shipping options may differ or not be available depending on the
                final destination (PO Box, Armed Services and Outlying
                Territories, etc.).
              </p>

              <Heading level={3} className="text-3xl">
                What are your shipping times?
              </Heading>

              <p>
                Shipping times vary according to the carrier (Post Canada or
                UPS) and the selected shipping option. Please note that shipping
                times of the carrier are out of control of Guitares Pellerin and
                might take longer as expected, especially during busy periods
                during the year.
              </p>

              {/* <div className="max-xs:block grid grid-cols-3">
                <div>
                  <Heading level={4} className="text-2xl">
                    Canada
                  </Heading>
                  <p>
                    <strong>1 to 5 business days*</strong>
                  </p>
                </div>
                <div>
                  <Heading level={4} className="text-2xl">
                    United States
                  </Heading>
                  <p>
                    <strong>3 to 6 business days*</strong>
                  </p>
                </div>
                <div>
                  <Heading level={4} className="text-2xl">
                    International
                  </Heading>
                  <p>
                    <strong>5 to 10 business days*</strong>
                  </p>
                </div>
              </div>

              <br />
              <p>
                <strong>*</strong>estimated shipping times
              </p> */}

              <Heading level={3} className="text-3xl">
                Do you include duties and import taxes in your prices?
              </Heading>
              <p>
                Duties and import taxes are not included and remain entirely
                your responsibility. Your order may incur duties, fees and
                import taxes upon delivery (included VAT). These charges are
                determined by your local customs authority. Payment of these is
                necessary to release your order from customs. For more
                information, please consult your local customs office.
              </p>
              <Heading level={3} className="text-3xl">
                How do I check the status of my order?
              </Heading>
              <p>
                When your order has shipped, you will receive an email
                notification which will include a tracking number you can use to
                check its status. Please allow <strong>48 hours</strong> for the
                tracking information to become available.
              </p>
              <br />
              <p>
                If you haven’t received your order{' '}
                <strong>
                  within 14 days of receiving your shipping confirmation email
                </strong>
                , please contact{' '}
                <UnderlinedButton to="/contact">
                  Michel Pellerin
                </UnderlinedButton>{' '}
                with your name and order number, and he will look into it for
                you.
              </p>
            </div>
          </section>

          <div className="my-24 h-[1px] w-full bg-gray-400" />

          <section className="max-md:block grid grid-cols-[200px_1fr] gap-20">
            <Heading level={2} className="!mt-0">
              Returns
            </Heading>

            <div>
              <p className="!mt-4">
                Any order is <strong>exchangeable but not refundable</strong>.
                To exchange an item, please contact{' '}
                <UnderlinedButton to="/contact">
                  Michel Pellerin
                </UnderlinedButton>{' '}
                first.
              </p>
              <br />
              <p>
                You can exchange any item purchased{' '}
                <strong>within 14 days from the delivery date</strong>. Make
                sure the item you return is in its original condition.
              </p>
              <br />
              <p>
                You can return your parcel as your own expense using any postal
                service. However, as the parcel remains your responsibility
                until it arrives with us, please remember to keep proof of
                dispatch.
              </p>
            </div>
          </section>
        </div>
      </article>
    </Layout>
  );
}
